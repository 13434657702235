import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from './Icons/logo.svg';
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { FaDiscord, FaYoutube, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className='bg-light p-5 mt-5'>
        <Container className='text-center'>
          <Row xs={1} md={2}>
            <Col md={3} className='text-center text-md-start'>
              <Image src={logo} className='w-75 mb-3' alt="Logo" />
              <p className='px-1'>
              I specialize in oboe recordings, offering top-quality sound for musicians and composers.
              </p>
            </Col>
            <Col md={1}></Col>
            <Col md={8}>
              <Row xs={1} sm={3}>
                <Col className='text-center text-md-start mb-3 mb-sm-0'>
                  <h5 className='text-uppercase fw-bold'>Menu</h5>
                  <p className='mb-2 ps-1'>
                    <a href='/' className='text-reset'>Home</a>
                  </p>
                  <p className='mb-2 ps-1'>
                    <a href='/about' className='text-reset'>About</a>
                  </p>
                  <p className='mb-2 ps-1'>
                    <a href='/shop' className='text-reset'>Shop</a>
                  </p>
                  <p className='mb-2 ps-1'>
                    <a href='/contact' className='text-reset'>Contact</a>
                  </p>
                </Col>
                <Col className='text-center text-md-start mb-3 mb-sm-0'>
                  <h5 className='text-uppercase fw-bold'>Social Media</h5>
                  <p className='mb-2 ps-1'>
                    <FaInstagram className='me-2'/>
                    <a href='https://www.instagram.com/oboerecordss/' className='text-reset'>oboerecordss</a>
                  </p>
                  <p className='mb-2 ps-1'>
                    <FaYoutube className='me-2'/>
                    <a href='https://www.youtube.com/@juliaoboe' className='text-reset'>Oboe Records</a>
                  </p>
                </Col>
                <Col className='text-center text-md-start'>
                  <h5 className='text-uppercase fw-bold'>Contact</h5>
                  <p className='mb-2 ps-1 text-nowrap'>
                    <HiOutlineEnvelope className='me-2' />
                    <a href='mailto:juliaoboee@gmail.com' className='text-reset'>juliaoboee@gmail.com</a>
                  </p>
                  <p className='mb-2 ps-1 text-nowrap'>
                    <FaDiscord className='me-2'/>
                    oboejuliaa
                  </p>
                  <p className='mb-2 ps-1 text-nowrap'>
                    <IoLocationOutline className='me-2' />
                    Zielona Góra, Poland
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='text-center p-3 text-aqua'>
        © Copyright 2024 by OboeRecords
      </div>
    </>
  );
}

export default Footer;