import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import sonata from './Images/sonata.mp4';
import ferling from './Images/ferling.mp4';
import sheherezade from './Images/sheherezade.mp4';
import '../index.css';

const ProjectsAbout = () => {
    const [youtubeID] = useState('F8dzAEK4pfE');
    const [youtubeID2] = useState('HeUSYE6lWgg');
    const [Tetris] = useState('G2YqugCBu9I');

    return (
        <>
            <Container className="d-flex flex-column justify-content-between my-5">
                <Row className="mb-4">
                    <h1
                        className="text-aqua fw-semibold mb-0"
                        style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
                    >
                        My Projects
                    </h1>
                    <span className="text-aqua fw-light fs-5">
                        Here are a few examples of my latest and most exciting projects.
                    </span>
                </Row>
                <Row xs={1} md={2}>
                    <Col>
                        <iframe
                            className="video"
                            title="Youtube player"
                            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={`https://youtube.com/embed/${youtubeID2}?autoplay=0`}
                        ></iframe>
                        <h1 className="pt-2 fs-2 text-center fw-light">Merry Go Round of Life - Howl's Moving Castle</h1>
                        <h5 className="mb-4 text-center text-gray">Joe Hisaishi</h5>
                    </Col>
                    <Col>
                        <iframe
                            className="video"
                            title="Youtube player"
                            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
                        ></iframe>
                        <h1 className="pt-2 fs-2 text-center fw-light">Harry Potter soundtrack</h1>
                        <h5 className="mb-4 text-center text-gray">Petr Potter Machane</h5>
                    </Col>
                    <Col className="mb-4">
                        <iframe
                            className="video"
                            title="Youtube player"
                            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={`https://youtube.com/embed/${Tetris}?autoplay=0`}
                        ></iframe>
                        <h1 className="pt-2 fs-2 text-center fw-light">Tetris Main Theme</h1>
                        <h5 className="mb-4 text-center text-gray">Music Based on a Russian Folk Song arr. Tyler Hatfield</h5>
                    </Col>
                    <Col className="mb-4">
                        <video className="video" controls>
                            <source src={sonata} type="video/mp4" />
                        </video>
                        <h1 className="pt-2 fs-2 text-center fw-light">Oboe Sonata in D Major Ad libitum</h1>
                        <h5 className="mb-4 text-center text-gray">Camille Saint-Saëns</h5>
                    </Col>
                    <Col className="mb-4">
                        <video className="video" controls>
                            <source src={ferling} type="video/mp4" />
                        </video>
                        <h1 className="pt-2 fs-2 text-center fw-light">3 Duos Concertantes for 2 Oboes, Op. 13</h1>
                        <h5 className="mb-4 text-center text-gray">Franz Wilhelm Ferling</h5>
                    </Col>
                    <Col className="mb-4">
                        <video className="video" controls>
                            <source src={sheherezade} type="video/mp4" />
                        </video>
                        <h1 className="pt-2 fs-2 text-center fw-light">Scheherazade mvt. 2, Oboe Solo</h1>
                        <h5 className="mb-4 text-center text-gray">N. Rimsky-Korsakov</h5>
                    </Col>
                </Row>
            </Container>
            <br />
        </>
    );
};

export default ProjectsAbout;
